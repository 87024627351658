import React from "react"
import bannerStyles from "../styles/scss/_banner.module.scss"
import ButtonVnt from "./button-vnt"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const bannerStyle = bannerImg => ({
  backgroundImage: `url(${bannerImg})`,
})

const overlayStyle = overlayOpacity => ({
  background: `rgba(0, 0, 0, ${overlayOpacity})`,
})

const headlineStyle = (color, fontSize) => ({
  color,
  fontSize,
})

const sublineStyle = (color, fontSize) => ({
  color,
  fontSize,
})

export class Banner extends React.Component {
  render() {
    const {
      heroImage,
      headline,
      headlineColor__limio_color,
      headlineFontSize,
      subline,
      sublineColor__limio_color,
      sublineFontSize,
      textPosition,
      overlayOpacity,
    } = this.props

    return (
      <div
        className={`w-100 ${bannerStyles.HeroBanner}`}
        style={bannerStyle(heroImage)}
      >
        <div
          className={`${bannerStyles.HeroOverlay}`}
          style={overlayStyle(overlayOpacity)}
        ></div>
        <div
          className={`${bannerStyles.HeroWrapper}   ${
            textPosition.selected.id === "Center" ? bannerStyles.isCenter : ""
          }`}
        >
          <div
            className={
              textPosition.selected.id === "Center"
                ? `${bannerStyles.CenterText}`
                : textPosition.selected.id === "Left"
                ? `${bannerStyles.LeftText}`
                : textPosition.selected.id === "Right"
                ? `${bannerStyles.RightText}`
                : ""
            }
          >
            <h1
              className={`${bannerStyles.TitleBanner}`}
              style={headlineStyle(
                headlineColor__limio_color,
                headlineFontSize
              )}
            >
              {headline}
            </h1>
            <h2
              className={`mb-5 ${bannerStyles.SublineBanner}`}
              style={sublineStyle(sublineColor__limio_color, sublineFontSize)}
            >
              {subline}
            </h2>

            <AnchorLink
              className={`${bannerStyles.banner_btn}`}
              to="/#contact-us"
              title="CONOCE MÁS"
            />
          </div>
        </div>
      </div>
    )
  }

  static defaultProps = {
    heroImage:
      "https://res.cloudinary.com/vangtry-technology/image/upload/v1594763021/vangtry%20site/Inttro-vangtry-blue_dtugwm.jpg",
    headline: "Diseño y Desarrollo",
    headlineColor__limio_color: "#fff",
    headlineFontSize: "3.9em",
    subline:
      "de apps mobile, web & desktop con la mas alta calidad. Admistración de bases de datos, marketing digital y más.",
    sublineColor__limio_color: "#fff",
    sublineFontSize: "1.7em",
    textPosition: {
      options: [
        { label: "TopLeft", id: "TopLeft" },
        { label: "Left", id: "Left" },
        { label: "BottomLeft", id: "BottomLeft" },
        { label: "TopRight", id: "TopRight" },
        { label: "Right", id: "Right" },
        { label: "BottomRight", id: "BottomRight" },
        { label: "TopCenter", id: "TopCenter" },
        { label: "Center", id: "Center" },
        { label: "BottomCenter", id: "BottomCenter" },
      ],
      selected: { label: "center", id: "Center" },
    },
    overlayOpacity: "0.04",
  }
}

export default Banner
