import React from "react"
import ourServicesStyles from "../../styles/scss/_scnt-our-services.module.scss"
import CardVnt from "../card-vnt"
import {
  faCode,
  faStore,
  faDatabase,
  faMagic,
} from "@fortawesome/free-solid-svg-icons"

function SectionOurServices(props) {
  return (
    <div
      className={`section_padding_100 bg-white ${ourServicesStyles.special_area}`}
      id="about"
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 ">
            <div className={`${ourServicesStyles.section_heading}`}>
              <h2 className="title-text">Nuestros Servicios.</h2>
              <div
                className={`mb-5 ${ourServicesStyles.underline_shape}`}
              ></div>
            </div>
          </div>
        </div>
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-12 col-md-6 col-lg-4">
            <CardVnt
              iconName={faCode}
              title="Software Factory."
              bodyParagraph="
              Apcompañamiento en todas las etapas del ciclo de vida del desarrollo de su proyecto."
            ></CardVnt>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <CardVnt
              iconName={faDatabase}
              title="Database."
              bodyParagraph="Consultorias, administración, modelado y mejoras en tiempos de consultas."
            ></CardVnt>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <CardVnt
              iconName={faStore}
              title="Tienda en linea."
              bodyParagraph="Amenta tus ventas y llega a más clientes sin ser un experto, nosotros lo hacemos por ti."
            ></CardVnt>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <CardVnt
              iconName={faMagic}
              title="Marketing Digital."
              bodyParagraph="Administración y seguimiento de campañas en redes sociales."
            ></CardVnt>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionOurServices
