import React from "react"
import Jumbotron from "react-bootstrap/Jumbotron"
import promoJumbotronStyles from "../styles/scss/_promojumbotron.module.scss"
import ButtonVnt from "./button-vnt"

function PromoJumbotron(props) {
  const { title, bodyParagraph } = props

  return (
    <div>
      <Jumbotron
        fluid
        className={`mb-0 ${promoJumbotronStyles.PromoJumbotron}`}
      >
        <div className={`container `}>
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1 className={`title-text`}>{title}</h1>
              <p className="paragpah-text">{bodyParagraph}</p>
            </div>
            <div className="col-md-4 text-right">
              <p>
                <ButtonVnt
                  linkTo="/tienda-en-linea/"
                  textLabel="Ver más"
                  buttonType="ghostInvert"
                ></ButtonVnt>
              </p>
            </div>
          </div>
        </div>
      </Jumbotron>
    </div>
  )
}

export default PromoJumbotron
