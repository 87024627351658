import React from "react"
import Jumbotron from "react-bootstrap/Jumbotron"
import FlutterLogo from "../../styles/img/powered-logos/flutter_icon.svg"
import OrcaleLogo from "../../styles/img/powered-logos/oracle-logo.svg"

import poweredLogosStyles from "../../styles/scss/_poweredtech.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faReact,
  faAngular,
  faDigitalOcean,
  faSass,
  faHtml5,
  faJs,
  faNode,
  faBootstrap,
  faGitlab,
  faSlackHash,
} from "@fortawesome/free-brands-svg-icons"

function SectionPoweredTech(props) {
  const { bodyParagraph } = props

  return (
    <div>
      <Jumbotron fluid className={`mb-0`}>
        <div className={`container`}>
          <p
            className={`paragpah-text mb-1 ${poweredLogosStyles.title_powered}`}
          >
            {bodyParagraph}
          </p>
          <div className={`mb-5 ${poweredLogosStyles.underline_shape}`}></div>
          <div
            className={`row no-gutters text-center ${poweredLogosStyles.logo_container}`}
          >
            <div className={`col-3 col-md-1 col-lg-1 mb-3`}>
              <FlutterLogo className={`${poweredLogosStyles.powered_logo}`} />
            </div>
            <div className="col-3 col-md-1 col-lg-1 mb-3">
              <FontAwesomeIcon size="2x" icon={faReact} />
            </div>
            <div className="col-3 col-md-1 col-lg-1 mb-3">
              <FontAwesomeIcon size="2x" icon={faAngular} />
            </div>
            <div className="col-3 col-md-1 col-lg-1 mb-3">
              <FontAwesomeIcon size="2x" icon={faDigitalOcean} />
            </div>
            <div className="col-3 col-md-1 col-lg-1 mb-3">
              <FontAwesomeIcon size="2x" icon={faSass} />
            </div>
            <div className="col-3 col-md-1 col-lg-1 mb-3">
              <FontAwesomeIcon size="2x" icon={faHtml5} />
            </div>

            <div className="col-3 col-md-1 col-lg-1">
              <FontAwesomeIcon size="2x" icon={faBootstrap} />
            </div>
            <div className="col-3 col-md-1 col-lg-1 mb-3">
              <FontAwesomeIcon size="2x" icon={faJs} />
            </div>
            <div className="col-3 col-md-1 col-lg-1 mb-3">
              <FontAwesomeIcon size="2x" icon={faNode} />
            </div>
            <div className="col-3 col-md-1 col-lg-1 mb-3">
              <FontAwesomeIcon size="2x" icon={faGitlab} />
            </div>
            <div className="col-3 col-md-1 col-lg-1 mb-3">
              <FontAwesomeIcon size="2x" icon={faSlackHash} />
            </div>
            <div className={`col-3 col-md-1 col-lg-1 mb-3`}>
              <OrcaleLogo />
            </div>
          </div>
        </div>
      </Jumbotron>
    </div>
  )
}

export default SectionPoweredTech
