import React from "react"
import sectionBlogTrendsStyles from "../../styles/scss/_blogtrends.module.scss"
import ButtonVnt from "../button-vnt"
import TrendCard from "../trend-card"
import { useStaticQuery, graphql } from "gatsby"

function SectionBlogTrends() {
  const data = useStaticQuery(
    graphql`
      {
        allContentfulPostsVangtry(
          filter: { node_locale: { eq: "en-US" } }
          sort: { fields: publishedDate, order: DESC }
          limit: 4
        ) {
          edges {
            node {
              id
              title
              slug
              publishedDate(formatString: "MMM YYYY")
              featuredImage {
                fluid(maxWidth: 750) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <div
      className={`section_padding_100 bg-white ${sectionBlogTrendsStyles.mt_150} ${sectionBlogTrendsStyles.special_description_content}`}
    >
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 col-lg-6 col-xl-6">
            <div className={`special_description_content`}>
              <div className="section_heading">
                <h2 className="title-text">Tendencias y Noticias.</h2>
                <div className={`mb-5 underline_shape`}></div>
              </div>

              <p className="paragpah-text">
                Entérese de las últimas noticias en tecnología, transformación
                digital, experiencia de usuario, , Big Data, Gobierno de Datos y
                otros temas de interés.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {data.allContentfulPostsVangtry.edges.map(edge => {
            return (
              <div key={edge.node.id} className="col-12 col-md-4 col-lg-3 mb-3">
                <TrendCard
                  title={edge.node.title}
                  featuredImage={edge.node.featuredImage.fluid}
                  imgTitle={edge.node.title}
                  linkPost={`/blog/${edge.node.slug}/`}
                  author="Equipo vangTry"
                >
                  {edge.node.title}
                </TrendCard>
              </div>
            )
          })}
        </div>
        <div className={`${sectionBlogTrendsStyles.blogtrend_area}`}>
          <div className="app-download-btn">
            <ButtonVnt
              linkTo="/blog/"
              textLabel="Ir al Blog"
              buttonType="default"
            ></ButtonVnt>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionBlogTrends
