import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import Banner from "../components/banner"
import PromoJumbotron from "../components/promo-jumbotron"
import SectionOurServices from "../components/sections-layout/sctn-our-services"
import SectionPoweredTech from "../components/sections-layout/sctn-powered-tech"
import SectionBlogTrends from "../components/sections-layout/sctn-blog-trends"
import ContactForm from "../components/sections-layout/sctn-contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Banner></Banner>
    <SectionOurServices></SectionOurServices>
    <SectionPoweredTech bodyParagraph="Powered by."></SectionPoweredTech>
    <SectionBlogTrends></SectionBlogTrends>
    <PromoJumbotron
      title="¿Necesitas vender por internet?"
      bodyParagraph="
      Nosotros tenemos la solución perfecta
      para aumentar el alcance de tu negocio."
    ></PromoJumbotron>
    <ContactForm></ContactForm>
  </Layout>
)

export default IndexPage
