import React from "react"
import { useForm } from "react-hook-form"
import { TextField, makeStyles } from "@material-ui/core"
import contactusStyles from "../../styles/scss/_contactus.module.scss"
import ButtonVnt from "../button-vnt"
import emailjs from "emailjs-com"
import AlertDialog from "../send-email-status-dialog"

function ContactForm() {
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const useStyles = makeStyles({
    root: {
      "& label.Mui-focused": {
        color: "#1b232d",
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: "#1b232d",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#1b232d",
        },
      },
    },
    textField: {
      marginTop: "1em",
    },
  })

  const classes = useStyles()

  const titleText = "Mensaje Enviado"
  const bodyText = "Pronto nos pondermos en contacto con usted."

  // Submit event
  const onSubmit = (data, e) => {
    // console.log("Submit event", e)

    // alert(JSON.stringify(data))

    sendEmail(e)
  }

  // Emailjs send email
  function sendEmail(e) {
    e.preventDefault()

    emailjs
      .sendForm(
        "gmailAdmin",
        "template_3WsqlJYJ",
        e.target,
        "user_isW9VLQ6AYoMhswLlQ1kz"
      )
      .then(
        result => {
          handleClickOpen()
          e.target.reset() // reset after form submit
        },
        error => {
          alert(
            "No se pudo enviar el mensaje, intente nuevamente o envienos un correo a admin@vangtry.com"
          )
          console.log(error.text)
        }
      )
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div
      className={`section_padding_100 clearfix ${contactusStyles.footer_contact_area}`}
      id="contact-us"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className={`${contactusStyles.section_heading}`}>
              <h2 className="title-text">¡Póngase en contacto con nosotros!</h2>
              <div className={`${contactusStyles.underline_shape}`}></div>
            </div>
            <div
              className={`paragpah-text my-4 ${contactusStyles.contactItem}`}
            >
              <p>
                Diligencie los campos y nos comunicaremos con usted lo más
                pronto posible.
              </p>
            </div>
            <div className={`paragpah-text ${contactusStyles.contactItem}`}>
              <p>
                <span>Dirección:</span> Calle 10 # 82A 35 - Bogotá
                D.C.
              </p>
            </div>
            <div className={`paragpah-text ${contactusStyles.contactItem}`}>
              <p>
                <span>Teléfono:</span> +57 300 624 7265
              </p>
            </div>
            <div className={`paragpah-text ${contactusStyles.contactItem}`}>
              <p>
                <span>Email:</span> admin@vangtry.com
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`${contactusStyles.contact_from}`}>
              <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" name="to_name" value="vangtry-notify" />
                <input
                  type="hidden"
                  name="to_email"
                  value="andy.torres@vangtry.com"
                />
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <TextField
                      type="text"
                      label="Nombre"
                      variant="outlined"
                      name="from_name"
                      size="small"
                      error={!!errors.from_name}
                      color="primary"
                      fullWidth
                      inputRef={register({
                        required: true,
                        maxLength: 100,
                        pattern: /^[A-Za-z]+$/i,
                      })}
                      helperText={errors.from_name && "Verifique este campo"}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <TextField
                      type="text"
                      label="Email"
                      variant="outlined"
                      name="from_email"
                      size="small"
                      fullWidth
                      error={!!errors.from_email}
                      inputRef={register({
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                      helperText={errors.from_email && "Verifique este campo"}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <TextField
                      type="number"
                      label="Celular"
                      variant="outlined"
                      name="from_telefono"
                      size="small"
                      fullWidth
                      error={!!errors.from_telefono}
                      inputRef={register({
                        required: true,
                        maxLength: 12,
                      })}
                      helperText={
                        errors.from_telefono && "Verifique este campo"
                      }
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <TextField
                      label="Mensaje"
                      name="message_html"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      error={!!errors.message_html}
                      inputRef={register({ required: true })}
                      helperText={errors.message_html && "Verifique este campo"}
                    />
                  </div>
                </div>

                <ButtonVnt
                  textLabel="Enviar"
                  buttonType="ghostSubmit"
                  onClick={() => reset()}
                ></ButtonVnt>
              </form>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div>
        <AlertDialog
          open={open}
          onClose={handleClose}
          titleText={titleText}
          bodyText={bodyText}
        ></AlertDialog>
      </div>
    </div>
  )
}

export default ContactForm
