import React from "react"
import cardvntStyles from "../styles/scss/_cardvnt.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function CardVnt(props) {
  const { title, bodyParagraph, iconName } = props

  return (
    <div className={`text-center ${cardvntStyles.single_special}`}>
      <div className={`${cardvntStyles.single_icon}`}>
        <FontAwesomeIcon size="2x" icon={iconName} />
      </div>
      <h4 className="title-text">{title}</h4>
      <p className="paragpah-text">{bodyParagraph}</p>
    </div>
  )
}

export default CardVnt
